<template>
  <div class="LaPaginator">
    <b-pagination
      v-model="/* eslint-disable vue/no-mutating-props */ pagination.current_page"
      :total-rows="pagination.total"
      :per-page="pagination.per_page"
      :last-number="true"
      :first-number="true"
      :limit="pageLimit"
      align="fill"
      size="lg"
      @page-click="(bvEvent, page) => $emit('page-click', bvEvent, page)"
      first-text="First"
      prev-text="Prev"
      next-text="Next"
      last-text="Last"
    ></b-pagination>
  </div>
</template>

<script>
export default {
  name: "la-paginator",
  props: {
    pagination: {
      total: 5,
      count: 2,
      per_page: 2,
      current_page: 2,
      total_pages: 3,
    },
  },
  data() {
    return {
      pageLimit: 10,
    };
  },
  methods: {
    track() {},
    setPageLimit() {
      this.pageLimit = window.window.innerWidth < 840 ? 4 : 10;
    },
  },
  computed: {},
  created() {
    window.addEventListener("resize", this.setPageLimit);
  },
  destroyed() {
    window.removeEventListener("resize", this.setPageLimit);
  },
};
</script>
