<template>
  <!--begin::Advance Table Widget 2-->
  <div class="card card-custom card-stretch gutter-b">
    <!--begin::Header-->
    <div class="card-header border-0 pt-5">
      <h3 class="card-title align-items-start flex-column mt-5">
        <span class="card-label font-weight-bolder text-dark">
          {{ $t("GENERAL.MY_ORDERS") }}
        </span>
        <span class="text-muted mt-3 font-weight-bold font-size-sm" v-if="false"> More than 400+ new members </span>
      </h3>
    </div>
    <hr class="mb-10" />
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body pt-3 pb-0 shadow-xs">
      <!--begin::Table-->
      <div class="blockquote" v-if="dataToShow.length === 0 && status !== 'loading'">
        {{ $t("GENERAL.NO_RECORDS_FOUND") }}
      </div>
      <div class="blockquote justify-content-center" v-if="status === 'loading'">
        <b-row class="justify-content-md-center">
          <b-col cols="12" md="auto"><b-spinner variant="success"></b-spinner></b-col>
        </b-row>
      </div>

      <div class="table-responsive h-100 p-2" v-if="dataToShow.length > 0 && status !== 'loading'">
        <table class="table table-borderless table-vertical-center rounded striped">
          <thead>
            <tr class="border p-0">
              <th class="p-2 text-center" style="min-width: 100px">{{ $t("GENERAL.DATE") }}</th>
              <th class="p-2 text-center" style="min-width: 100px">{{ $t("GENERAL.TOTAL") }}</th>
              <th class="p-2 text-center" style="min-width: 150px">{{ $t("GENERAL.STATUS") }}</th>

              <th class="p-2" style="min-width: 50px"></th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(item, i) in dataToShow">
              <tr v-bind:key="i" class="border">
                <td class="pl-10 text-center">
                  <a class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">
                    {{ item.created_at }}
                  </a>
                </td>
                <td class="text-right">
                  <span class="text-primary font-weight-bold"> R$ {{ item.total }} </span>
                </td>
                <td class="text-center">
                  <span class="label label-lg label-inline">
                    {{ item.purchase_origin.status }}
                  </span>
                </td>
                <td class="text-right">
                  <router-link
                    :to="{
                      name: 'my-orders-detail',
                      params: { id: item.id },
                    }"
                    class="btn btn-icon btn-light-success btn-sm"
                  >
                    <i class="fa fa-eye fa-sm"></i>
                  </router-link>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>

      <!--end::Table-->
    </div>
    <!--end::Body-->
    <!--end::Advance Table Widget 2-->
    <div class="card-footer">
      <la-paginator v-if="dataToShow.length > 0" @page-click="(bvEvent, page) => getData(page)" :pagination="currentMyOrdersPaginator"> </la-paginator>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import LaPaginator from "@/view/pages/components/LaPaginator/LaPaginator";
import ContentService from "@/core/services/content.service";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GET_MY_ORDERS } from "@/core/services/store/my-orders.module";

export default {
  name: "commerce-my-orders",
  data() {
    return {
      status: "loading",
      type: "all",
    };
  },
  components: { LaPaginator },

  computed: {
    ...mapGetters({ layoutConfig: "layoutConfig", myOrders: "myOrders/myOrders", currentMyOrdersPaginator: "myOrders/currentMyOrdersPaginator" }),

    dataToShow() {
      return this.myOrders || [];
    },
  },
  methods: {
    getContentPhoto(photo_url) {
      return ContentService.getContentPhoto(photo_url);
    },
    getData(page) {
      this.status = "loading";
      this.$store
        .dispatch("myOrders/" + GET_MY_ORDERS, { page })
        .then(() => {})
        .finally(() => {
          this.status = "loaded";
        });
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$tc("GENERAL.DASHBOARD", 3), route: "dashboard" }, { title: this.$tc("GENERAL.MY_ORDERS", 3) }]);
    setTimeout(() => this.getData(1), 600);
    return this.dataToShow;
  },
};
</script>
