/**
 * Service to call HTTP request via Axios
 */

const ContentService = {
  getContentPhoto(photo_url) {
    return photo_url ? photo_url + "?r=" + Math.random() : "/media/svg/icons/Shopping/Ticket.svg";
  },
};

export default ContentService;
